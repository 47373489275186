import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSubscriptionCheck } from './useSubscriptionCheck';
import apiClient from '../../apiClient';

function CheckSubscription() {
  const { hasSubscription, isChecking, error } = useSubscriptionCheck();
  const [onboardingStatus, setOnboardingStatus] = useState(null);

  useEffect(() => {
    if (hasSubscription === true) {
      checkOnboardingStatus();
    }
  }, [hasSubscription]);

  const checkOnboardingStatus = async () => {
    try {
      const response = await apiClient.get('/portal/onboarding-status');
      setOnboardingStatus(response.data.isOnboarded);
    } catch (error) {
      console.error('Error checking onboarding status:', error);
      setOnboardingStatus('error');
    }
  };

  if (isChecking) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-900 to-blue-900 text-white">
        <p className="text-2xl">Checking subscription status...</p>
      </div>
    );
  }

  if (error || onboardingStatus === 'error') {
    return <Navigate to="/subscription-error" />;
  }

  if (hasSubscription === false) {
    return <Navigate to="/subscription-required" />;
  }

  if (hasSubscription === true) {
    if (onboardingStatus === null) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-900 to-blue-900 text-white">
          <p className="text-2xl">Checking onboarding status...</p>
        </div>
      );
    } else if (onboardingStatus === false) {
      return <Navigate to="/onboarding" />;
    } else {
      return <Navigate to="/" />;
    }
  }

  return null;
}

export default CheckSubscription;