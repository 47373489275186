import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Mic, Image, Send } from 'lucide-react';

const Dashboard = ({ startNewConversation }) => {
  const [userInput, setUserInput] = useState('');

  const handleStartConversation = () => {
    if (userInput.trim()) {
      startNewConversation(userInput);
    }
  };

  return (
    <div className="p-8 max-w-6xl mx-auto">
      <Card className="mb-8">
        <CardContent className="pt-6">
          <h2 className="text-2xl font-bold text-center mb-4">How can I help you today?</h2>
          <div className="flex items-center space-x-2 mb-4">
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Start typing or ask a question..."
              className="flex-grow p-2 border rounded-md"
            />
            <Button onClick={handleStartConversation}>
              <Send className="h-4 w-4 mr-2" />
              Send
            </Button>
          </div>
          <div className="flex justify-center space-x-4">
            <Button variant="outline">
              <Image className="h-4 w-4 mr-2" />
              Upload Photo
            </Button>
            <Button variant="outline">
              <Mic className="h-4 w-4 mr-2" />
              Voice Note
            </Button>
          </div>
        </CardContent>
      </Card>

      <Tabs defaultValue="today" className="w-full">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="today">Today</TabsTrigger>
          <TabsTrigger value="week">This Week</TabsTrigger>
          <TabsTrigger value="month">This Month</TabsTrigger>
          <TabsTrigger value="overall">Overall Theme</TabsTrigger>
        </TabsList>
        <TabsContent value="today">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Today's Focus</h3>
            </CardHeader>
            <CardContent>
              <p>Your main goal: Complete project proposal</p>
              <p>Tasks completed: 3/5</p>
              <p>Mood: Productive</p>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="week">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">This Week's Progress</h3>
            </CardHeader>
            <CardContent>
              <p>Goals achieved: 2/3</p>
              <p>Productivity score: 85%</p>
              <p>Top focus area: Work projects</p>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="month">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Monthly Overview</h3>
            </CardHeader>
            <CardContent>
              <p>Habits maintained: 4/5</p>
              <p>Personal growth: +12%</p>
              <p>Areas for improvement: Time management</p>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="overall">
          <Card>
            <CardHeader>
              <h3 className="text-lg font-semibold">Overall Theme</h3>
            </CardHeader>
            <CardContent>
              <p>Life satisfaction: 8/10</p>
              <p>Current focus: Building a balanced lifestyle</p>
              <p>Long-term goal: Career advancement</p>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Dashboard;