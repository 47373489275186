import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import socketIOClient from "socket.io-client";
import Dashboard from './Dashboard';
import ChatInterface from './ChatInterface';
import Sidebar from './Sidebar';
import apiClient from '../../apiClient';

const SOCKET_IO_ENDPOINT = "https://a.life.activatedhuman.earth";

const Portal = () => {
  const [threads, setThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [showDashboard, setShowDashboard] = useState(true);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const socketRef = useRef(null);
  const isInitializingRef = useRef(false);

  // useEffect(() => {
  //   console.log('Current Thread ID changed:', currentThreadId);
  // }, [currentThreadId]);

  // useEffect(() => {
  //   console.log(`Portal effect - showDashboard: ${showDashboard}, currentThreadId: ${currentThreadId}`);
  // }, [showDashboard, currentThreadId]);


  const initializeSocket = useCallback(async () => {
    if (socketRef.current || isInitializingRef.current) {
      return;
    }

    isInitializingRef.current = true;

    try {
      const newSocket = socketIOClient(SOCKET_IO_ENDPOINT, {
        path: '/api/socket.io',
        withCredentials: true,
        transports: ['websocket']
      });

      newSocket.on("connect", async () => {
        console.log("Connected to server");
        try {
          const token = await getAccessTokenSilently();
          newSocket.emit('announce', { token });
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      });

      newSocket.on("authenticated", (data) => {
        if (data.status === 'success') {
          console.log("Successfully authenticated with WebSocket");
        } else {
          console.error("WebSocket authentication failed:", data.message);
        }
      });

      newSocket.on("disconnect", (reason) => {
        console.log("Disconnected from server:", reason);
        socketRef.current = null;
      });

      socketRef.current = newSocket;
    } catch (error) {
      console.error("Error initializing socket:", error);
    } finally {
      isInitializingRef.current = false;
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && !socketRef.current) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [isAuthenticated, initializeSocket]);


  useEffect(() => {
    if (isAuthenticated) {
      fetchThreads();
    }
  }, [isAuthenticated]);


  const fetchThreads = useCallback(async () => {
    try {
      const response = await apiClient.get('/portal/threads');
      setThreads(response.data);
      if (response.data.length > 0 && !currentThreadId) {
        setCurrentThreadId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  }, [currentThreadId]);

  
  const startNewConversation = useCallback(async () => {
    try {
      const response = await apiClient.post('/portal/threads');
      console.log('New thread response:', response.data);
      setThreads(prevThreads => [...prevThreads, response.data]);
      setCurrentThreadId(response.data.id);
      setShowDashboard(false);
    } catch (error) {
      console.error('Error creating new thread:', error);

    }
  }, []);

  // const handleThreadCreated = useCallback((newThreadId) => {
  //   if (newThreadId) {
  //     // setThreads(prevThreads => [...prevThreads, newThread]);
  //     setCurrentThreadId(newThreadId);
  //     console.log('New thread created:', newThreadId);  // Add this log
  //   } else {
  //     console.error('Invalid thread data received:', newThreadId);
  //   }
  // }, []);

  // const handleThreadClick = useCallback((threadId) => {
  //   setCurrentThreadId(threadId);
  //   setShowDashboard(false);
  // }, []);

  if (isLoading) {
    return <div className="flex items-center justify-center h-full">Loading...</div>;
  }


  
  // For development purposes, you might want to comment out this check
  // if (!isAuthenticated) {
  //   return <div className="flex items-center justify-center h-screen">Please log in to access the portal.</div>;
  // }

  return (
    <div className="flex h-full">
      <Sidebar
        threads={threads}
        currentThreadId={currentThreadId}
        setCurrentThreadId={setCurrentThreadId}
        setShowDashboard={setShowDashboard}
        startNewConversation={startNewConversation}
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-y-auto">
          {showDashboard ? (
            <Dashboard 
              setShowDashboard={setShowDashboard}
              startNewConversation={startNewConversation}
            />
          ) : (
            <ChatInterface 
              key={currentThreadId}
              threadId={currentThreadId} 
              setThreadId={setCurrentThreadId}
              setShowDashboard={setShowDashboard} 
              socket={socketRef.current}
              // onThreadCreated={handleThreadCreated}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default Portal;