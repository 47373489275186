import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient';

export function useSubscriptionCheck() {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [hasSubscription, setHasSubscription] = useState(null);
  const [isChecking, setIsChecking] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkSubscription() {
      if (isLoading || !isAuthenticated) return;
      
      setIsChecking(true);
      try {
        const response = await apiClient.get('/portal/check-subscription');

        setHasSubscription(response.data.hasSubscription);
        
        if (!response.data.hasSubscription) {
          navigate('/subscription-required');
        }
      } catch (err) {
        setError(err.message);
        navigate('/subscription-error');
      } finally {
        setIsChecking(false);
      }
    }

    checkSubscription();
  }, [getAccessTokenSilently, isAuthenticated, isLoading, navigate]);

  return { hasSubscription, isChecking, error };
}