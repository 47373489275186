import React from 'react';
import { Link } from 'react-router-dom';
import { LogIn } from 'lucide-react';

const WelcomePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-700 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-xl p-8 text-center">
        <h1 className="text-3xl font-bold text-blue-900 mb-6">Welcome to the Life Framework!</h1>
        <div className="text-gray-600 mb-8">
          <p className="mb-4">Thank you for subscribing to Activated Human.</p>

          <p className="text-xl text-center mb-8 max-w-2xl">
            Your AI Life Managing Assistant is ready to help you achieve your goals.
          </p>

        </div>
        <Link 
          to="/" 
          className="inline-flex items-center bg-blue-600 text-white font-semibold py-3 px-6 rounded-full hover:bg-blue-700 transition duration-300"
        >
          <LogIn className="w-5 h-5 mr-2" />
          Continue to Dashboard
        </Link>

      </div>
    </div>
  );
};


export default WelcomePage;
