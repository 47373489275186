import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient';

const questions = [
  {
    question: "What should we call you?",
    tooltip: "For example: Your name, nickname, or preferred title",
  },
  {
    question: "What time do you typically wake up / go to sleep?",
    tooltip: "For example: Wake up at 7 AM, go to sleep at 11 PM",
  },
  {
    question: "What is the first thing you like to do in the day once you are awake?",
    tooltip: "For example: Meditate, exercise, or have a cup of coffee",
  },
  {
    question: "How do you typically manage your schedule or routine?",
    tooltip: "For example: Using a planner, digital calendar, or to-do list",
  },
  {
    question: "What are typically your greatest barriers to achieving your goals?",
    tooltip: "For example: Procrastination, lack of time, or distractions",
  },
  {
    question: "What is your personal greatest core strength that you use each day?",
    tooltip: "For example: Persistence, creativity, or empathy",
  },
];

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    checkOnboardingStatus();
  }, []);

  const checkOnboardingStatus = async () => {
    try {
      const response = await apiClient.get('/portal/onboarding-status');
      if (response.data.isOnboarded) {
        navigate('/welcome');
      }
    } catch (error) {
      console.error('Error checking onboarding status:', error);
    }
  };

  const handleAnswer = (answer) => {
    setAnswers({ ...answers, [currentStep]: answer });
  };

  const handleNext = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      submitOnboarding();
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const submitOnboarding = async () => {
    try {
      // Wrap the answers in an "answers" object
      const formattedAnswers = {
        answers: Object.fromEntries(
          Object.entries(answers).map(([key, value]) => [questions[parseInt(key)].question, value])
        )
      };
      
      await apiClient.post('/portal/onboarding', formattedAnswers);
      navigate('/welcome');
    } catch (error) {
      console.error('Error submitting onboarding data:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-700 flex items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-xl p-8">
        <h2 className="text-2xl font-bold mb-4">{questions[currentStep].question}</h2>
        <textarea
          className="w-full h-32 p-2 border rounded mb-4"
          value={answers[currentStep] || ''}
          onChange={(e) => handleAnswer(e.target.value)}
          placeholder="Your answer here..."
        />
        <p className="text-sm text-gray-600 mb-4">{questions[currentStep].tooltip}</p>
        <div className="flex justify-between">
          <button
            onClick={handlePrevious}
            disabled={currentStep === 0}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className="bg-blue-600 text-white px-4 py-2 rounded"
          >
            {currentStep === questions.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;